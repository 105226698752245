@import '../../styles/common';
$light: #ffffff;
.fabContainer {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9;
  transition: all .3s ease-out;
  &.hide {
    transform: translateY(200px); }
  &.bottom {
    bottom: 6rem;
    @include from--desktop {
      bottom: 2rem; } } }
.helpContent {
  position: absolute;
  bottom: 96px;
  right: 0;
  width: 320px;
  height: 0;
  padding: 0;
  background-color: $light;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @include for--phone-only {
    width: 286px;
    bottom: 64px;
    h2 {
      font-size: 1.25rem; }
    h3 {
      font-size: 1rem; } }
  @include for--phone-landscape-only {
    bottom: 10px;
    right: 70px; }
  &.visible {
    transition: height 0.3s ease-in-out;
    height: 260px;
    padding: 32px; }
  div {
    border-bottom: solid 1px #D5D8DA;
    padding-bottom: 23px;
    p {
      margin: 8px 0 0 0;
      color: #8F92A2;
      font-size: 14px;
      font-weight: 500; } }

  h3 {
    color: #5F9BA8;
    display: flex;
    align-items: center;
    margin: 23px 0;
    svg {
      margin-right: 8px; } } }
button.fab {
  width: 55px;
  height: 55px;
  border-radius: 500em;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  svg, img {
    margin: 0; } }
.screen-reader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }
