// Color definitions

// -------- Palette -----------


$mildBlue:      #4BBFEC;
$darkBlue:      #2795D3;
$greenishBlue:  #2BBEC8;
$lightGreen:    #5CB97F;
$lightRed:      #F97A7A;
$lightYellow:   #FDC761;
$congoPink:     #EF7172;

$dark:          #343536;
$light:         #ffffff;

$fiftyShadesGray: #898888;
$darkGray:      #616161;
$gray:          #6F7C88;
$lightGray:     #9F9FA3;
$lighterGray: #C4C4C4;
$veryLightGray: #D9DEE0;
$superLightGray: #E3E8EB; //button
$bluishGray:    #F6F7FC;
$lightestGray:  #E5E5E5;

// colors below


// -------- Colors -----------

$themeColor: $lightGreen; // was before #078A54

$primary-color:     $mildBlue;
$info-color:        $darkBlue;
$success-color:     $lightGreen;
$processing-color:  $greenishBlue;
$error-color:       $congoPink;
$highlight-color:   $darkBlue;
$warning-color:     $lightYellow;

$normal-color:      $lightGray;
$white:             $light;
$black:             $dark;
$text-color:        #8F92A2;



// -------- Background Colors -----------

// Background color for `<body>`
$body-background:       $bluishGray;

// Base background color for most components
$component-background:  $light;
