h1 {
  font-size: 32px;
  line-height: 1;
  color: $dark;
  margin: 0;
  small {
    font-size: .65em;
    display: block;
    opacity: .5;
    margin-bottom: .25em; }
  .letter-spacing-tight {
    letter-spacing: -0.666667px; }

  @include from--laptop {
    small {
      font-size: .5em; } } }


.text-center {
  text-align: center; }

h2, h3 {
  margin: 0; }

h3 {
  font-size: 1.25rem;
  color: $lightGray; }

h2 {
  font-size: 1.5rem;
  color: $dark; }

h4 {
  @include font-size($heading-4-size);
  margin: 0; }

h5 {
  @include font-size($font-size-lg);
  margin: 0; }

// should be used for text in some screens
.hint {
  color: $text-color;
  font-weight: 400;
  @include font-size(14px, 17px); }
