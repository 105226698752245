@import '../../../styles/common';
$button-color: #047A40;
.step-success {
  .mainContainer_wrapper {
    max-width: 500px; } }

.ni-step-onboard-others {
  padding: 1rem;
  text-align: center;
  h2 {
     max-width: 540px; }
  p {
    max-width: 540px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 22px;
    color: #74778B; }
  @include for--phone-only {
    padding: 0; } }
