@import '../../../styles/common';

.doc_wrapper {

  h1, h2, h3 {
    letter-spacing: -0.666667px;
    color: #343536; }
  h1 {
    font-weight: bold;
    @include font-size(32px, 32px); }
  h2 {
    font-weight: bold;
    @include font-size(24px, 32px); }
  h3 {
    font-weight: 500;
    @include font-size(20px, 32px); }
  p, ul {
    @include font-size(14px, 22px);
    font-weight: 500;
    color: $text-color; }
  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 12px; } } }
  p {
    a {
      color: #5CB97F;
      text-decoration: none; } }

  .document-updated-date {
    font-size: .875em; }

  section {
    margin-bottom: 3em; } }
