.camera-guides {
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .camera-guides-pointer {
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(10px);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: rgba(120,120,128,0.2);
      &.error {
        transform: scale(1.2);
        background: rgba(194, 63, 63, 0.2) !important;
        img {
          display: none; } }
      &.scanning {
        transform: scale(1.3); }
      $circlePadding: 8px;
      &::after {
        position: absolute;
        content: "";
        display: block;
        top: $circlePadding;
        left: $circlePadding;
        right: $circlePadding;
        bottom: $circlePadding;
        background: rgb(194, 63, 63);
        border-radius: 1000px;
        transition: all .2s ease-out;
        transform: scale(0); }
      &.error::after {
        transform: scale(1); } }
    .camera-guides-message {
      backdrop-filter: blur(10px);
      background: rgba(120,120,128,0.2);
      color: white;
      transition: all .3s ease-out;
      margin-top: 16px;
      max-width: 320px;
      padding: 18px;
      border-radius: 8px;
      text-align: center;
      text-shadow: 0px 1px 4px rgba(black,0.3);
      font-size: 18px;
      font-weight: bold;
      background: rgba(0,0,0,0.7) !important; } }

.flip-card {
  width: 96px;
  height: 60px;
  perspective: 600px;

  .flip-card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
    animation: cardSpin 2000ms cubic-bezier(.17,.67,.51,.18) 0ms infinite; }

  &-front, &-back {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    backface-visibility: hidden; }

  &-back {
    transform: rotateY( 180deg ); } }



.fallback-capture {
  position: fixed;
  bottom: -100px;
  width: 62px;
  height: 62px;
  background-color: #ffffff;
  margin: auto;
  z-index: 999;
  transition: bottom 0.35s ease-out;
  &.visible {
    bottom: 55px; }
  &:active {
    transform: scale(0.9); }
  &:before {
    content: '';
    width: 72px;
    height: 72px;
    border: solid 4px #ffffff;
    border-radius: 50%;
    position: absolute; } }
/* animations */
@keyframes cardSpin {
  from {
    transform: rotateY(0deg); }
  to {
    transform: rotateY(-360deg); } }




