@import '../../../styles/common';

$colorSwitch: #5CB97F;
$colorPrice: #F19920;

form.product-options-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 48px;
  .option-wrapper {
    &.last {
      order: 1; }
    .product-option-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px $superLightGray;
      padding: 32px 0;
      position: relative;
      order: 0;
      &.last {
        order: 1; }
      &.sub-option {
        .option_desc {
          margin-left: 40px;
          max-width: 270px; } }
      &.no-option {}
      .option_desc {
        text-align: left;
        position: relative;
        p {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          margin: 0; } }
      span {
        font-size: 14px;
        color: $text-color;
        &.annotation {
          right: 0; } }
      .price {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: $colorPrice;
        margin-right: 60px;
        &.free {
          color: $colorSwitch; } }
      .switch-wrapper {
        display: flex;
        .switch {
          display: flex;
          margin: 0; } } } }
  .form-controls {
    margin-top: 48px;
    order: 3;
    display: flex;
    justify-content: space-between; }

  /* The switch box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    min-width: 48px;
    width: 48px;
    height: 24px; }

  /* Hide checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }

  /* slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 6px;
    bottom: 4px;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s; }

  input:checked + .slider {
    background-color: $colorSwitch; }

  input:focus + .slider {
    box-shadow: 0 0 1px $colorSwitch; }

  input:checked + .slider:before {
    transform: translateX(22px); }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%; } } }
