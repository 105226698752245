@import '../../../styles/common';

.is-loading {
  .mainContainer {
    display: none; } }

.loading-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10; }

.loading-square {
  width: 200px;
  height: 200px;
  background: transparent;
  color: black;
  transform: translateY(20px);
  transition: all .3s ease-in-out;
  opacity: 0;
  .is-loading & {
    opacity: 1;
    transform: translateY(0px); } }
