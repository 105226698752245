@import '../../styles/common';

$heading-color: #3A4C53;

.ni-step-confirm-email {
  color: $text-color;
  text-align: center;
  margin: auto;
  height: 100%;
  h3 {
    font-size: 32px;
    color: $heading-color; }
  img {
    margin: 0 auto 76px; }
  button {
    margin-top: 48px; } }
