.alert {
  &.toast {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: .5rem;
    font-weight: bold;
    svg {
      margin-right: 1rem; }
    &.is-error {
      color: $error-color;
      background: lighten($error-color, 27%); } } }
