.ni-timer {
  padding: 0;
  text-align: right;
  color: rgba(black, 0.2);
  flex: 1;
  small {
    display: block; }
  .timer {
    font-size: 2em;
    font-family: monospace; } }
