@import '../../../styles/common';

.step-verify-email {
  .mainContainer_wrapper {
    max-width: 600px;
    width: 100%;
    @include from--laptop {
      .mainContainer {
        overflow: hidden;
        padding: 48px; } }
    @include from--tablet {
      min-width: 600px; } } }
