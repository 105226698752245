// Mixins and functions to help


// define space
//  '1': '0.25rem', // 4px
//  '2': '0.5rem',  // 8px
//  '4': '1rem',    // 16px
//  '6': '1.5rem',  // 24px
//  '8': '2rem',    // 32px
//  '12': '3rem',   // 48px
@function spacer($unit: 1) {
  $base-spacing: 4px;
  @return $base-spacing * $unit; }



// Define default font size
// ex: calculateRem(16px)
@function calculateRem($size) {
  $rem-size: $size / 16px;
  @return $rem-size * 1rem; }


// Font size and line height mixin
// ex: @include font-size(16px, 32px);
@mixin font-size($size, $line-height: false) {
  font-size: $size;
  font-size: calculateRem($size);
  line-height: $line-height-base;

  @if $line-height != false {}
		line-height: ($line-height / $size); }


// Create a css grid with min size and gap
// ex: @include auto-grid(252px, 32px)
@mixin auto-grid($size: 16rem, $gap: 1rem, $fill-type: auto-fill) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: repeat($fill-type, minmax($size, 1fr)); }

// a mixin for grid repeat
@mixin grid-repeat($fill-type: auto-fill, $min: 16rem, $max: 1fr) {
  grid-template-columns: repeat($fill-type, minmax($min, $max)); }

// A mixin to facilitate flex
@mixin flexbox {
  display: -webkit-box; // old
  display: -moz-box; // old
  display: -ms-flexbox; // ie
  display: -webkit-flex; // new
  display: flex; } // new


// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow; }

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify; }

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align; }

// just use this and forget
// ex: @include flexAlignCenter
@mixin flexAlignCenter() {
  @include flexbox();
  @include flex-flow(row wrap);
  @include justify-content(center);
  @include align-items(center); }


// Media queries for responsive goodiness
// @include from--tablet
//    padding: 20px
//

@mixin for--phone-only {
  @media (max-width: $screen-sm) {
    @content; } }
@mixin for--phone-landscape-only {
  @media (max-width: $screen-md) and (orientation: landscape) {
    @content; } }

@mixin from--phone {
  @media (min-width: $screen-xs) {
    @content; } }

@mixin from--tablet-portrait {
  @media (min-width: $screen-sm) {
    @content; } }

@mixin from--tablet {
  @media (min-width: $screen-md) {
    @content; } }

@mixin from--laptop {
  @media (min-width: $screen-lg) {
    @content; } }

@mixin from--desktop {
  @media (min-width: $screen-xl+100) {
    @content; } }

@mixin from--desktop-huge {
  @media (min-width: $screen-xl+600) {
    @content; } }

@mixin ie-only {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content; } }


@mixin from($size) {
  @if $size == 'xs' {
    @include from--phone {
      @content; } }
  @else if $size == 'sm' {
    @include from--tablet-portrait {
      @content; } }
  @else if $size == 'md' {
    @include from--tablet {
      @content; } }
  @else if $size == 'lg' {
    @include from--laptop {
      @content; } }
  @else if $size == 'xl' {
    @include from--desktop {
      @content; } }
  @else if $size == 'xxl' {
    @include from--desktop-huge {
      @content; } }
  @else {
    @content; } }

@mixin reset-button-style {
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:focus {
    outline: 0; } }
@mixin reset-input-style {
  background: transparent;
  border: none;
  &:focus {
    outline: 0; } }

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; }
  @-moz-keyframes #{$name} {
    @content; }
  @-ms-keyframes #{$name} {
    @content; }
  @keyframes #{$name} {
    @content; } }
