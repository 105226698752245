@import '../../../styles/common';

.ni-step-terms-and-conditions {
  .doc_wrapper {
    padding: 6%;
    @include from--desktop {
      padding: 4%; }
    h1, h2, h3, h4 {
      color: $gray; }
    header {
      margin-bottom: 2em;
      h1 {
        font-size: 24px; }
      p {
        margin-top: 0; } }

    margin: 1rem 0;
    @include from--desktop {
      padding: 2rem; } } }
