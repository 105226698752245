@import '../../../styles/common';

.noMatch {
  &_page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bluishGray; }
  &_content {
    text-align: center;
    h1 {
      font-size: 3rem; }
    h2 {
      color: $text-color; }
    .button {
      margin-top: 2rem; } } }
