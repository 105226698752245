@import '../../styles/common';

.ni-footer {
  max-width: $max-content-width;
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  color: #8F92A2;
  @include from--tablet {
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem; }
  p {
    flex-grow: 1;
    font-size: 14px; }
  .separator {
    margin: 0 20px; }
  a {
    font-size: 14px;
    text-decoration: none;
    color: #8F92A2; } }
