.form-look {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

.dropdown-menu-content {
    min-width: 220px;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2); }

.dropdown-menu-arrow {
    fill: white; }
