.code-input {
    border-radius: 50%;
    width: 242px !important; }

.code-input input, .code-input input:first-child, .code-input input:last-child {
    // border-radius: 50%
    width: 40px !important;
    height: 40px !important;
    margin-left: 8px;
    margin-bottom: 8px !important;
    border: 1px solid #c0c0c0 !important; }
