@import '../../../styles/common';

.ni-header {
  max-width: $max-content-width;
  margin: auto;
  width: 100%;
  @include flexAlignCenter();
  margin-bottom: 5vw;
  @include from--tablet {
    margin-bottom: 2rem; }

  .ni-logo {
    cursor: pointer;
    height: auto;
    align-self: center;
    max-width: 60%;
    img {
      display: block;
      max-width: 100%;
      max-height: 48px;
      @include from--tablet {
        max-height: 80px; } }
    @include from--tablet {
      max-width: 300px; } } }
