.camera-step {
  h4 {
    color: #8F92A2;
    font-weight: 400; }
  .doc-type-list {
    display: flex;
    flex-flow: column;
    margin: 46px 0; }
  .button-fallback {
    margin-left: 20px; } }
