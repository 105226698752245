html, body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-background;
  background-size: cover;
  height: 100vh; }

code {
  font-family: $font-family-code; }
.App .main,
.App main {
  min-height: 100vh;
  height: 100px; // IE
  padding: 5vh 8px;
  display: flex;
  flex: 1;
  flex-basis: 1;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  @include from--tablet {
    padding: 2rem; } }
