@import 'styles/index.sass';

// temporary
nav ul {
  display: flex;
  justify-content: space-between; }

nav li {
  flex: auto;
  list-style: none; }
