@import '../../../styles/common';

$focusColor: $themeColor;
$labelColor: rgba(#818181, 0.6);

.form-label {
  font-size: $font-size-base;
  color: $labelColor;
  font-weight: 500;
  &:after {
    @extend %pseudo-block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0%;
    background: $focusColor;
    transition: all .3s ease-in-out; } }
