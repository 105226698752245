// -------- Font Family -----------

$font-family: 'Gilroy', 'Roboto', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-family-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// -------- Font Sizes -----------
// TODO convert headings to rem

$font-size-base:  14px;
$font-size-lg:    $font-size-base + 2px;
$font-size-sm:    13px;
$heading-1-size:  32px;
$heading-2-size:  24px;
$heading-3-size:  20px;
$heading-4-size:  18px;

$line-height-base:   1.12;


// -------- Button Sizes -----------

$btn-font-weight:   500;
$btn-font-size-lg:  $font-size-lg;
$btn-font-size-sm:  $font-size-base;
