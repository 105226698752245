@font-face {
  font-family: "Gilroy";
  font-display: auto;
  src: url('/fonts/radomir_tinkov_-_gilroy-regular-webfont.woff2') format('woff2'),
  url('/fonts/radomir_tinkov_-_gilroy-regular-webfont.woff') format('woff'),
  url('/fonts/radomir_tinkov_-_gilroy-regular-webfont.ttf') format('truetype') {} }

@font-face {
  font-family: "Gilroy";
  font-display: auto;
  src: url('/fonts/radomir_tinkov_-_gilroy-bold-webfont.woff2') format('woff2'),
  url('/fonts/radomir_tinkov_-_gilroy-bold-webfont.woff') format('woff'),
  url('/fonts/radomir_tinkov_-_gilroy-bold-webfont.ttf') format('truetype') {}
  font-weight: 700; }

@font-face {
  font-family: "Gilroy";
  font-display: auto;
  src: url('/fonts/radomir_tinkov_-_gilroy-semibold-webfont.woff2') format('woff2'),
  url('/fonts/radomir_tinkov_-_gilroy-semibold-webfont.woff') format('woff'),
  url('/fonts/radomir_tinkov_-_gilroy-semibold-webfont.ttf') format('truetype') {}
  font-weight: 600; }

@font-face {
  font-family: "Gilroy";
  font-display: auto;
  src: url('/fonts/radomir_tinkov_-_gilroy-medium-webfont.woff2') format('woff2'),
  url('/fonts/radomir_tinkov_-_gilroy-medium-webfont.woff') format('woff'),
  url('/fonts/radomir_tinkov_-_gilroy-medium-webfont.ttf') format('truetype') {}
  font-weight: 500; }

@font-face {
  font-family: "Gilroy";
  font-display: auto;
  src: url('/fonts/radomir_tinkov_-_gilroy-thin-webfont.woff2') format('woff2'),
  url('/fonts/radomir_tinkov_-_gilroy-thin-webfont.woff') format('woff'),
  url('/fonts/radomir_tinkov_-_gilroy-thin-webfont.ttf') format('truetype') {}
  font-weight: 300; }
