
@import '../../../styles/common';


.ni-alert-message {
  padding: 16px 30px;
  background: linear-gradient(0deg, rgba(255, 224, 125, 0.2), rgba(255, 224, 125, 0.2)), #FFFFFF;
  border-radius: 12px;

  @include font-size(13px, 17px);
  font-weight: 500;
  color: $text-color;

  > .title {
    @include font-size(18px, 20px);
    color: $dark;
    margin-bottom: $padding-xxs; } }

