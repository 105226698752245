
// -------- Colors override -----------

.ni-color-success {
  color: $lightGreen !important; }

.ni-bg-color-success {
  background-color: $lightGreen !important; }

.ni-color-danger {
  color: $lightRed !important; }

.ni-bg-color-danger {
  background-color: $lightRed !important; }

.ni-color-platinum {
  color: $darkBlue !important; }

.ni-bg-color-platinum {
  background-color: $darkBlue !important; }

.ni-color-gold {
  color: $lightYellow !important; }

.ni-bg-color-gold {
  background-color: $lightYellow !important; }

.ni-color-silver {
  color: $lightGray !important; }

.ni-bg-color-silver {
  background-color: $lightGray !important; }

.ni-color-dark {
  color: $fiftyShadesGray !important; }

.ni-bg-color-dark {
  background-color: $fiftyShadesGray !important; }

.ni-color-gray {
  color: $gray !important; }

.ni-color-text {
  color: $text-color !important; }
