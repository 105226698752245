@import '../../../styles/common';

.disclosure-holder {
    display: flex;
    justify-content: center; }
.disclosure {
    margin: 1rem;
    text-align: initial;

    a {
        color: $themeColor;
        text-decoration: none;
        font-weight: bold;
        margin-left: 0.4rem; } }

.switcher {
    display: flex;
    align-items: centere;
    margin: 0.6rem 0; }
